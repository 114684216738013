(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function numbersAnimation() {
  var numbers = document.querySelectorAll('[data-ow="number"]');
  var observerTarget = document.querySelector("[data-numbers]");
  if (numbers.length && observerTarget) {
    // Numbers Animation
    var animation = function animation() {
      numbers.forEach(function (numberElement) {
        var numberElementWidth = getComputedStyle(numberElement).width;
        numberElement.style.minWidth = numberElementWidth;
        var total = Number(numberElement.innerText);
        var increment = Math.floor(total / 100);
        var start = 0;
        var timer = setInterval(function () {
          start = start + increment + 1;
          numberElement.innerText = start;
          if (start > total) {
            numberElement.innerText = String(total).padStart(2, 0);
            clearInterval(timer);
          }
        }, 38 * Math.random());
      });
    }; // === Mutation Event
    var handleMutation = function handleMutation(mutation) {
      var target = mutation[0].target;
      if (target.classList.contains("aos-animate")) {
        observer.disconnect();
        animation();
      }
    };
    var observer = new MutationObserver(handleMutation);
    observer.observe(observerTarget, {
      attributes: true
    });
  }
}
var _default = exports["default"] = numbersAnimation;

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function aosInit() {
  AOS.init({
    once: true,
    duration: 900,
    offset: 300
  });
}
var _default = exports["default"] = aosInit;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function faq() {
  var faqItems = document.querySelectorAll('[data-faq="item"]');
  if (faqItems.length) {
    var toggleFaqItem = function toggleFaqItem(indexTarget) {
      faqItems[indexTarget].classList.toggle("active");
    };
    faqItems.forEach(function (item, index) {
      item.addEventListener("click", function () {
        return toggleFaqItem(index);
      });
    });
  }
}
var _default = exports["default"] = faq;

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function forceAOSanimationOnLoad() {
  var elements = document.querySelectorAll("[data-force-animation]");
  if (elements.length) {
    setTimeout(function () {
      elements.forEach(function (element) {
        return element.classList.add("aos-animate");
      });
    }, 50);
  }
}
var _default = exports["default"] = forceAOSanimationOnLoad;

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function headerMobile() {
  var headerNav = document.querySelector('[data-header="nav"]');
  var headerBtn = document.querySelector('[data-header="btn-mobile"]');
  var overlayBg = document.querySelector("[data-overlay-bg]");
  if (headerNav && headerBtn && overlayBg) {
    var toggleHeaderMobile = function toggleHeaderMobile() {
      html.classList.toggle("no-scroll-mobile");
      headerNav.classList.toggle("active");
      headerBtn.classList.toggle("active");
      if (overlayBg.classList.contains("active")) {
        setTimeout(function () {
          overlayBg.classList.remove("active");
        }, 200);
      } else {
        overlayBg.classList.toggle("active");
      }
    };
    var html = document.documentElement;
    headerBtn.addEventListener("click", toggleHeaderMobile);
    overlayBg.addEventListener("click", toggleHeaderMobile);
  }
}
var _default = exports["default"] = headerMobile;

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function hideBtnWhatsOnFooter() {
  var footer = document.querySelector("[data-footer]");
  var btnWhats = document.querySelector("[data-btn-whatsapp]");
  if (footer && btnWhats) {
    var checkBtnWhatsappPosition = function checkBtnWhatsappPosition() {
      var footerTop = footer.getBoundingClientRect().top;
      var isFooterVisible = footerTop - scroll90Percent < 300;
      if (isFooterVisible) {
        btnWhats.classList.remove("active");
      } else if (!btnWhats.classList.contains("active")) {
        btnWhats.classList.add("active");
      }
    };
    var scroll90Percent = window.innerHeight * 0.95;
    checkBtnWhatsappPosition();
    window.addEventListener("scroll", checkBtnWhatsappPosition);
  }
}
var _default = exports["default"] = hideBtnWhatsOnFooter;

},{}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _aosInit = _interopRequireDefault(require("./aosInit.js"));
var _forceAOSanimationOnLoad = _interopRequireDefault(require("./forceAOSanimationOnLoad.js"));
var _animationNumbers = _interopRequireDefault(require("./animationNumbers.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function loaderPage() {
  var loader = document.querySelector('[data-loader-page="wrapper"]');
  loader.classList.add("loaded");
  setTimeout(function () {
    loader.classList.add("hide");
    setTimeout(function () {
      loader.remove();
      (0, _aosInit["default"])();
      (0, _forceAOSanimationOnLoad["default"])();
      (0, _animationNumbers["default"])();
    }, 800);
  }, 1800);
}
var _default = exports["default"] = loaderPage;

},{"./animationNumbers.js":1,"./aosInit.js":2,"./forceAOSanimationOnLoad.js":4}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toastfy = _interopRequireDefault(require("./toastfy.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function newsletterForm() {
  var form = document.querySelector('[data-newsletter="form"]');
  if (form) {
    var checkEmailIsValid = function checkEmailIsValid() {
      var inputEmail = form.querySelector('[data-newsletter="input-email"]');
      var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail.value);
      if (!isValidEmail) {
        (0, _toastfy["default"])({
          status: "error",
          message: "Informe um e-mail válido!"
        });
        inputEmail.classList.add("invalid");
        setTimeout(function () {
          return inputEmail.classList.remove("invalid");
        }, 2000);
        return false;
      }
      return true;
    };
    var submitContactForm = function submitContactForm() {
      var inputEmail = form.querySelector('[data-newsletter="input-email"]');
      var btnSubmit = form.querySelector('[data-newsletter="btn-submit"]');
      (0, _toastfy["default"])({
        message: "This is a success message example"
      }); //  <- [felipe], pode remover essa linha

      // [felipe] fazer a requisição em baixo
      // $.ajax({
      //   url: baseURL + "/",
      //   type: "POST",
      //   data: formDataToJSON(new FormData(form)),
      //   contentType: "application/json",
      //   dataType: "json",
      //   crossOrigin: false,
      //   crossDomain: true,
      //   beforeSend: function () {
      //     btnSubmit.classList.add("loading");

      //     toast({ status: "warning", message: "Enviando dados..." });
      //   },
      //   success: function () {
      //     setTimeout(() => {
      //       toast({
      //         status: "success",
      //         message: "Mensagem enviada com sucesso!",
      //       });

      //       inputEmail.value = "";
      //     }, 800);
      //   },
      //   error: function ({ responseJSON }) {
      //     const msgResponse =
      //       responseJSON?.msg ||
      //       "Ops! Erro ao enviar a mensagem, tente novamente!";

      //     toast({ status: "error", message: msgResponse });

      //     btnSubmit.classList.remove("loading");
      //   },
      //   complete: function () {
      //     setTimeout(() => {
      //       btnSubmit.classList.remove("loading");
      //     }, 600);
      //   },
      // });
    };
    form.addEventListener("submit", function (event) {
      event.preventDefault();
      if (checkEmailIsValid()) {
        submitContactForm();
      }
    });
  }
}
var _default = exports["default"] = newsletterForm;

},{"./toastfy.js":10}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function outsideClick(_ref) {
  var element = _ref.element,
    events = _ref.events,
    callback = _ref.callback;
  var html = document.documentElement;
  var outside = 'data-outside';
  if (!element.hasAttribute(outside)) {
    events.forEach(function (userEvent) {
      setTimeout(function () {
        html.addEventListener(userEvent, handleOutsideClick);
      });
    });
    element.setAttribute(outside, '');
  }
  function handleOutsideClick(event) {
    if (!element.contains(event.target)) {
      element.removeAttribute(outside);
      events.forEach(function (userEvent) {
        html.removeEventListener(userEvent, handleOutsideClick);
      });
      callback();
    }
  }
}
var _default = exports["default"] = outsideClick;

},{}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function toast(_ref) {
  var _ref$status = _ref.status,
    status = _ref$status === void 0 ? "success" : _ref$status,
    _ref$message = _ref.message,
    message = _ref$message === void 0 ? "This is a message example" : _ref$message;
  var toastContainer = document.querySelector(".toast-container");
  if (toastContainer) {
    var _toast = createToast(status, message);
    toastContainer.appendChild(_toast);
    handleClickCloseToast();
    removeToast(_toast);
  }
}
function createToast(statusToast, messageToast) {
  var toastElement = document.createElement("div");
  toastElement.classList.add("toast-element", statusToast);
  toastElement.innerHTML = "\n      <div class=\"toast-message\">\n        ".concat(messageToast, "\n      </div>\n      <button class=\"toast-close no-config\" data-toast=\"close\">\n        <svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" xmlns:svgjs=\"http://svgjs.com/svgjs\" width=\"12\" height=\"11\" x=\"0\" y=\"0\" viewBox=\"0 0 329.26933 329\" style=\"enable-background:new 0 0 512 512\" xml:space=\"preserve\" class=\"\">\n          <g>\n            <path xmlns=\"http://www.w3.org/2000/svg\" d=\"m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0\" fill=\"#ffffff\" data-original=\"#fff\" class=\"\"></path>\n          </g>\n        </svg>\n      </button>\n      <div class=\"toast-progress\"></div>\n    ");
  return toastElement;
}
function removeToast(toastElement, clickedBtnClose) {
  setTimeout(function () {
    toastElement.classList.add("hide");
  }, clickedBtnClose ? 0 : 5000);
  setTimeout(function () {
    toastElement.classList.add("removing-content");
  }, clickedBtnClose ? 500 : 5500);
  setTimeout(function () {
    toastElement.remove();
  }, clickedBtnClose ? 2500 : 8000);
}
function handleClickCloseToast() {
  var btnsCloseToast = document.querySelectorAll('[data-toast="close"]');
  if (btnsCloseToast.length) {
    btnsCloseToast.forEach(function (btn) {
      btn.addEventListener("click", function () {
        var toastTargetClicked = this.parentElement;
        removeToast(toastTargetClicked, true);
      });
    });
  }
}
var _default = exports["default"] = toast;

},{}],11:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function numberFormat(number, decimals, dec_point, thousands_sep) {
  // Strip all characters but numerical ones.
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function toFixedFix(n, prec) {
      var k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };

  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}
var _default = exports["default"] = numberFormat;

},{}],12:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toastfy = _interopRequireDefault(require("../../global/toastfy.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function isSimulatorFormStep01Valid(form) {
  var inputs = form.querySelectorAll('[data-simulator-step="01"] input');
  var isAllInputsFilled = true;

  // check all inputs is empty
  inputs.forEach(function (elementForm) {
    if (elementForm.value === "" && !elementForm.disabled) {
      elementForm.classList.add("invalid");
      setTimeout(function () {
        return elementForm.classList.remove("invalid");
      }, 2000);
      isAllInputsFilled = false;
    }
  });
  if (!isAllInputsFilled) {
    (0, _toastfy["default"])({
      status: "error",
      message: "Preencha os campos obrigatórios!"
    });
    return isAllInputsFilled;
  }

  // check name
  var inputName = form.querySelector('[data-simulator="input-name"]');
  var inputNameValue = inputName.value;
  var isNameContainNumber = /\d/.test(inputNameValue);
  if (inputNameValue.length <= 2 || isNameContainNumber) {
    (0, _toastfy["default"])({
      status: "error",
      message: "Insira um nome válido"
    });
    inputName.classList.add("invalid");
    setTimeout(function () {
      return inputName.classList.remove("invalid");
    }, 2000);
    isAllInputsFilled = false;
    return isAllInputsFilled;
  }

  // check email
  var inputEmail = form.querySelector('[data-simulator="input-email"]');
  var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail.value);
  if (!isValidEmail) {
    (0, _toastfy["default"])({
      status: "error",
      message: "Informe um e-mail válido!"
    });
    inputEmail.classList.add("invalid");
    setTimeout(function () {
      return inputEmail.classList.remove("invalid");
    }, 2000);
    isAllInputsFilled = false;
    return isAllInputsFilled;
  }

  // check whatsapp
  var inputCellphone = form.querySelector('[data-simulator="input-cellphone"]');
  var inputCellphoneClean = inputCellphone.value.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").length;
  if (inputCellphone.value !== "" && inputCellphoneClean !== 11) {
    (0, _toastfy["default"])({
      status: "error",
      message: "Informe um número de celular válido!"
    });
    inputCellphone.classList.add("invalid");
    setTimeout(function () {
      return inputCellphone.classList.remove("invalid");
    }, 2000);
    isAllInputsFilled = false;
    return isAllInputsFilled;
  }

  // check CEP
  var inputCEP = form.querySelector('[data-simulator="input-cep"]');
  if (inputCEP.value.length !== 9) {
    (0, _toastfy["default"])({
      status: "error",
      message: "Informe um número de CEP válido!"
    });
    inputCEP.classList.add("invalid");
    setTimeout(function () {
      return inputCEP.classList.remove("invalid");
    }, 2000);
    isAllInputsFilled = false;
    return isAllInputsFilled;
  }
  return isAllInputsFilled;
}
var _default = exports["default"] = isSimulatorFormStep01Valid;

},{"../../global/toastfy.js":10}],13:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toastfy = _interopRequireDefault(require("../../global/toastfy.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function isSimulatorFormStep02Valid(form) {
  var inputs = form.querySelectorAll('[data-simulator-step="02"] select');
  var isAllInputsFilled = true;

  // check all inputs is empty
  inputs.forEach(function (elementForm) {
    if (elementForm.value === "" && !elementForm.disabled) {
      elementForm.classList.add("invalid");
      setTimeout(function () {
        return elementForm.classList.remove("invalid");
      }, 2000);
      isAllInputsFilled = false;
    }
  });
  if (!isAllInputsFilled) {
    (0, _toastfy["default"])({
      status: "error",
      message: "Preencha os campos obrigatórios!"
    });
    return isAllInputsFilled;
  }
  return isAllInputsFilled;
}
var _default = exports["default"] = isSimulatorFormStep02Valid;

},{"../../global/toastfy.js":10}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _isSimulatorFormStep01Valid = _interopRequireDefault(require("./isSimulatorFormStep01Valid.js"));
var _isSimulatorFormStep02Valid = _interopRequireDefault(require("./isSimulatorFormStep02Valid.js"));
var _simulatorFormAddMasks = _interopRequireDefault(require("./simulatorFormAddMasks.js"));
var _simulatorFormSubmit = _interopRequireDefault(require("./simulatorFormSubmit.js"));
var _simulatorFormUpdateStep = _interopRequireDefault(require("./simulatorFormUpdateStep.js"));
var _simulatorTypeSubmenu = _interopRequireDefault(require("./simulatorTypeSubmenu.js"));
var _simulatorUpdateCreditValues = _interopRequireDefault(require("./simulatorUpdateCreditValues.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function simulatorForm() {
  var form = document.querySelector('[data-simulator="form"]');
  if (form) {
    var handleClickSubmitStep01 = function handleClickSubmitStep01(event) {
      event.preventDefault();
      if ((0, _isSimulatorFormStep01Valid["default"])(form)) {
        (0, _simulatorFormUpdateStep["default"])(1);
      }
    };
    var handleClickSubmitStep02 = function handleClickSubmitStep02(event) {
      event.preventDefault();
      if ((0, _isSimulatorFormStep02Valid["default"])(form)) {
        (0, _simulatorFormSubmit["default"])(form);
      }
    }; // init function on page loaded
    var btnNext = form.querySelector('[data-simulator="btn-next"]');
    var btnBack = form.querySelector('[data-simulator="btn-back"]');
    var btnFinish = form.querySelector('[data-simulator="btn-finish"]');
    var inputRangeCredit = document.querySelector('[data-simulator-filters="input-range-credit"]');
    (0, _simulatorFormAddMasks["default"])();
    (0, _simulatorTypeSubmenu["default"])();
    (0, _simulatorUpdateCreditValues["default"])();
    btnNext.addEventListener("click", handleClickSubmitStep01);
    btnBack.addEventListener("click", function () {
      return (0, _simulatorFormUpdateStep["default"])(0);
    });
    btnFinish.addEventListener("click", handleClickSubmitStep02);
    inputRangeCredit.addEventListener("input", _simulatorUpdateCreditValues["default"]);
  }
}
var _default = exports["default"] = simulatorForm;

},{"./isSimulatorFormStep01Valid.js":12,"./isSimulatorFormStep02Valid.js":13,"./simulatorFormAddMasks.js":15,"./simulatorFormSubmit.js":16,"./simulatorFormUpdateStep.js":17,"./simulatorTypeSubmenu.js":18,"./simulatorUpdateCreditValues.js":19}],15:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function simulatorFormAddMasks() {
  var inputCellPhone = $('[data-simulator="input-cellphone"]');
  var inputCEP = $('[data-simulator="input-cep"]');
  inputCellPhone.length && inputCellPhone.mask("(00) 00000-0000");
  inputCEP.length && inputCEP.mask("99999-999", {
    reverse: true
  });
}
var _default = exports["default"] = simulatorFormAddMasks;

},{}],16:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _toastfy = _interopRequireDefault(require("../../global/toastfy.js"));
var _simulatorFormUpdateStep = _interopRequireDefault(require("./simulatorFormUpdateStep.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function simulatorFormSubmit(form) {
  var btnFinish = form.querySelector('[data-simulator="btn-finish"]');
  var btnBack = form.querySelector('[data-simulator="btn-back"]');
  var elementsForm = form.querySelectorAll("input, select");

  // ========= [COMEÇO] remover esse block de código
  btnFinish.classList.add("loading");
  btnBack.classList.add("untouchable");
  elementsForm.forEach(function (element) {
    console.log("-- element", element, element.value);
  });
  setTimeout(function () {
    (0, _simulatorFormUpdateStep["default"])(2);
  }, 1500);
  // ========= [FIM] remover esse block de código

  $.ajax({
    url: baseURL + "/",
    type: "POST",
    data: formDataToJSON(new FormData(form)),
    contentType: "application/json",
    dataType: "json",
    crossOrigin: false,
    crossDomain: true,
    beforeSend: function beforeSend() {
      btnFinish.classList.add("loading");
      btnBack.classList.add("untouchable");
    },
    success: function success() {
      setTimeout(function () {
        (0, _simulatorFormUpdateStep["default"])(2);
      }, 800);
    },
    error: function error(_ref) {
      var responseJSON = _ref.responseJSON;
      var msgResponse = (responseJSON === null || responseJSON === void 0 ? void 0 : responseJSON.msg) || "Ops! Erro ao enviar a simulação, tente novamente!";
      (0, _toastfy["default"])({
        status: "error",
        message: msgResponse
      });
      btnFinish.classList.remove("loading");
      btnBack.classList.remove("untouchable");
    },
    complete: function complete() {
      btnFinish.classList.remove("loading");
      btnBack.classList.remove("untouchable");
    }
  });
}
var _default = exports["default"] = simulatorFormSubmit;

},{"../../global/toastfy.js":10,"./simulatorFormUpdateStep.js":17}],17:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function simulatorFormUpdateStep(indexStep) {
  var boxesStep = document.querySelectorAll('[data-simulator="step"]');
  if (boxesStep.length) {
    boxesStep.forEach(function (box) {
      box.classList.remove("active");
    });
    boxesStep[indexStep].classList.add("active");
  }
}
var _default = exports["default"] = simulatorFormUpdateStep;

},{}],18:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _outsideClick = _interopRequireDefault(require("../../global/outsideClick"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function simulatorTypeSubMenu() {
  var btn = document.querySelector('[data-simulator="option-btn"]');
  if (btn) {
    var showSubMenu = function showSubMenu() {
      btn.classList.add("active");
      subMenu.classList.add("active");
      (0, _outsideClick["default"])({
        element: btn,
        events: ["click"],
        callback: function callback() {
          return hideSubMenu();
        }
      });
    };
    var hideSubMenu = function hideSubMenu() {
      btn.classList.remove("active");
      subMenu.classList.remove("active");
    };
    var updateBanner = function updateBanner(indexTarget) {
      var imgs = document.querySelectorAll('[data-simulator="bg"] img');
      imgs.forEach(function (img) {
        img.classList.remove("active");
      });
      imgs[indexTarget].classList.add("active");
    };
    var updateTypeValues = function updateTypeValues(typeTarget, indexTarget) {
      var typeText = typeTarget.innerText;
      if (options[indexTarget].classList.contains("active")) {
        hideSubMenu();
      } else {
        options.forEach(function (option) {
          return option.classList.remove("active");
        });
        options[indexTarget].classList.add("active");
        titlesType.forEach(function (title) {
          return title.innerText = typeText;
        });
        inputType.value = typeText.toLowerCase();
        hideSubMenu();
        updateBanner(indexTarget);
      }
    };
    var subMenu = document.querySelector('[data-simulator="option-list"]');
    var options = subMenu.querySelectorAll("li a");
    var inputType = document.querySelector('[data-simulator="input-type"]');
    var titlesType = document.querySelectorAll('[data-simulator="title-type"]');
    btn.addEventListener("click", function (event) {
      event.preventDefault();
      if (event.target === btn) {
        if (!btn.classList.contains("active")) {
          showSubMenu();
        } else {
          hideSubMenu();
        }
      }
    });
    options.forEach(function (option, index) {
      option.addEventListener("click", function (event) {
        event.preventDefault();
        updateTypeValues(option, index);
      });
    });
  }
}
var _default = exports["default"] = simulatorTypeSubMenu;

},{"../../global/outsideClick":9}],19:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _numberFormart = _interopRequireDefault(require("../../helpers/numberFormart.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function simulatorUpdateCreditValues() {
  var inputRangeCredit = document.querySelector('[data-simulator-filters="input-range-credit"]');
  inputRangeCredit.removeAttribute("step");

  // positioning available value in input
  // for (let index = 0; index < FetchResponseCreditValues.length; index++) {
  //   if (FetchResponseCreditValues[index] > inputRangeCredit.value) {
  //     inputRangeCredit.value = FetchResponseCreditValues[index];
  //     break;
  //   }
  // }

  // positioning filled line
  // and update tooltip value
  var inputRangeLine = document.querySelector('[data-simulator-filters="range-credit-line"]');
  var inputRangeValue = document.querySelector('[data-simulator-filters="range-value-box"]');
  var newValueFormatted = (0, _numberFormart["default"])($(inputRangeCredit).val(), 2, ",", ".");
  var newValue = Number((inputRangeCredit.value - inputRangeCredit.min) * 100 / (inputRangeCredit.max - inputRangeCredit.min));
  var newPosition = 10 - newValue * 0.2;

  // line position
  inputRangeLine.style.width = "calc(".concat(newValue, "% + (").concat(newPosition, "px))");

  // line value e position
  inputRangeValue.innerHTML = "<span>R$ ".concat(newValueFormatted, "</span>");
  inputRangeValue.style.left = "calc(".concat(newValue, "% + (").concat(newPosition, "px))");
}
var _default = exports["default"] = simulatorUpdateCreditValues;

},{"../../helpers/numberFormart.js":11}],20:[function(require,module,exports){
"use strict";

var _loaderPage = _interopRequireDefault(require("./modules/global/loaderPage.js"));
var _faq = _interopRequireDefault(require("./modules/global/faq.js"));
var _headerMobile = _interopRequireDefault(require("./modules/global/headerMobile.js"));
var _aosInit = _interopRequireDefault(require("./modules/global/aosInit.js"));
var _forceAOSanimationOnLoad = _interopRequireDefault(require("./modules/global/forceAOSanimationOnLoad.js"));
var _animationNumbers = _interopRequireDefault(require("./modules/global/animationNumbers.js"));
var _newsletterForm = _interopRequireDefault(require("./modules/global/newsletterForm.js"));
var _simulatorForm = _interopRequireDefault(require("./modules/pages/simulator/simulatorForm.js"));
var _hideBtnWhatsappOnFooter = _interopRequireDefault(require("./modules/global/hideBtnWhatsappOnFooter.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
(0, _headerMobile["default"])();
(0, _faq["default"])();
(0, _newsletterForm["default"])();
(0, _simulatorForm["default"])();
(0, _hideBtnWhatsappOnFooter["default"])();
window.addEventListener("load", function () {
  var loader = document.querySelector('[data-loader-page="wrapper"]');
  if (loader) {
    (0, _loaderPage["default"])();
  } else {
    (0, _aosInit["default"])();
    (0, _forceAOSanimationOnLoad["default"])();
    (0, _animationNumbers["default"])();
  }
});

},{"./modules/global/animationNumbers.js":1,"./modules/global/aosInit.js":2,"./modules/global/faq.js":3,"./modules/global/forceAOSanimationOnLoad.js":4,"./modules/global/headerMobile.js":5,"./modules/global/hideBtnWhatsappOnFooter.js":6,"./modules/global/loaderPage.js":7,"./modules/global/newsletterForm.js":8,"./modules/pages/simulator/simulatorForm.js":14}]},{},[20]);
